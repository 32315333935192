import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/paratriennale/paratriennale/loveletter/src/templates/works-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Caption = makeShortcode("Caption");
const BpHr = makeShortcode("BpHr");
const LinkButton = makeShortcode("LinkButton");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`井口直人`}</h1>
    <p>{`黄色と青、赤と緑、ピンクと黄色と組み合わせはいろいろ。いずれも鮮やかな二色を使ったカラーコピーの作品です。写っているものをよく見ると、ブレたり歪んだりした人の顔を見つけて、びっくりするかもしれません。作者の井口直人さんは、毎日決まった時間に、懸賞シールやレース柄のビニール袋など身の回りのお気に入りを添えて、自分の顔や手のコピーを取ります。施設の職員さんに「今日の色は？」と聞かれると、いつも「黄色」と答えるという井口さん。近所のコンビニにも毎日一人で通い、コピーの時間を楽しんでいます。`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1280px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "66.5625%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAACBf/EABUBAQEAAAAAAAAAAAAAAAAAAAQF/9oADAMBAAIQAxAAAAE7gsQTo2Kar//EABkQAQADAQEAAAAAAAAAAAAAAAIAAQMRIf/aAAgBAQABBQKglMbvtStEJkfQun//xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQMBAT8BITL/xAAYEQADAQEAAAAAAAAAAAAAAAAAAQIRMf/aAAgBAgEBPwGuDl6f/8QAGhABAAIDAQAAAAAAAAAAAAAAAQACEBExIf/aAAgBAQAGPwLdTk9wlXs2rCf/xAAaEAADAQEBAQAAAAAAAAAAAAAAARExIWHB/9oACAEBAAE/IVzca0xF8u/DpUVRForTPzhZQ//aAAwDAQACAAMAAAAQOP8A/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARMf/aAAgBAwEBPxDsyUG//8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAgEBPxB9bHRf/8QAGhABAQEBAQEBAAAAAAAAAAAAAREAIUExUf/aAAgBAQABPxD4qhPMiUFx7f1kCr3OBNBMrUgDjxlU6ke7/9k=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/7f08ca11e17f8714fbbe321cabeb05ef/ec4f2/naoto-iguchi.webp 320w", "/static/7f08ca11e17f8714fbbe321cabeb05ef/74ed4/naoto-iguchi.webp 640w", "/static/7f08ca11e17f8714fbbe321cabeb05ef/265b4/naoto-iguchi.webp 1280w", "/static/7f08ca11e17f8714fbbe321cabeb05ef/ed2e8/naoto-iguchi.webp 1920w", "/static/7f08ca11e17f8714fbbe321cabeb05ef/6b78f/naoto-iguchi.webp 2400w"],
          "sizes": "(max-width: 1280px) 100vw, 1280px",
          "type": "image/webp"
        }}></source>{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/7f08ca11e17f8714fbbe321cabeb05ef/9328e/naoto-iguchi.jpg 320w", "/static/7f08ca11e17f8714fbbe321cabeb05ef/f24a5/naoto-iguchi.jpg 640w", "/static/7f08ca11e17f8714fbbe321cabeb05ef/36316/naoto-iguchi.jpg 1280w", "/static/7f08ca11e17f8714fbbe321cabeb05ef/18346/naoto-iguchi.jpg 1920w", "/static/7f08ca11e17f8714fbbe321cabeb05ef/29666/naoto-iguchi.jpg 2400w"],
          "sizes": "(max-width: 1280px) 100vw, 1280px",
          "type": "image/jpeg"
        }}></source>{`
        `}<img parentName="picture" {...{
          "className": "gatsby-resp-image-image",
          "src": "/static/7f08ca11e17f8714fbbe321cabeb05ef/36316/naoto-iguchi.jpg",
          "alt": "naoto iguchi",
          "title": "naoto iguchi",
          "loading": "lazy",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          }
        }}></img>{`
      `}</picture>{`
    `}</span>
    <Caption before={`作品`} medium={`カラーコピー、257×364 (mm) 2016-2020頃`} mdxType="Caption">
  《無題》
    </Caption>
    <BpHr type={`dot`} color={`invert`} mdxType="BpHr" />
    <LinkButton color={`invert`} to={`/works/masayo-funakoshi/`} mdxType="LinkButton">
  応答する人: 船越雅代
    </LinkButton>
    <LinkButton color={`invert`} to={`/works/teppei-kaneuji/`} mdxType="LinkButton">
  応答する人: 金氏徹平
    </LinkButton>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      